<template>
  <div v-if="date">
    <div class="flix-form-group flix-text-right">
      <flixIcon class="icon" :id="'calendar'" />
      <select class="flix-form-control" v-model="date[0][2]" @change="setDate">
        <option v-for="key in days" :key="key" :value="$getNullsBefore(key + 1)">{{ $getNullsBefore(key + 1) }}</option>
      </select>
      <select class="flix-form-control" v-model="date[0][1]" @change="setDate">
        <option v-for="key in month" :key="key" :value="$getNullsBefore(key + 1)">{{ $t('constants.months')[key + 1].substr(0, 3) }}</option>
      </select>
      <select class="flix-form-control" v-model="date[0][0]" @change="setDate">
        <option v-for="key in years" :key="key" :value="key">{{ key }}</option>
      </select>
    </div>
    <div class="flix-form-group flix-text-right">
      <flixIcon class="icon" :id="'clock'" />
      <select class="flix-form-control" v-model="date[1][0]" @change="setDate">
        <option v-for="key in hours" :key="key" :value="$getNullsBefore(key)">{{ $getNullsBefore(key) }}</option>
      </select>&nbsp;:&nbsp;
      <select class="flix-form-control" v-model="date[1][1]" @change="setDate">
        <option v-for="key in minutes" :key="key" :value="$getNullsBefore(key * 5)">{{ $getNullsBefore(key * 5) }}</option>
        <option value="59">{{ $getNullsBefore(59) }}</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    value: Array,
    callback: Function
  },
  data () {
    return {
      days: new Array(31).keys(),
      month: new Array(12).keys(),
      years: this.getYears(),
      hours: new Array(24).keys(),
      minutes: new Array(12).keys(),
      date: false
    }
  },
  methods: {
    setDate () {
      this.callback(this.date)
      this.updateDate()
    },
    getYears () {
      var year = new Date().getFullYear()
      var r = []
      for (var i = 0; i < 2; i++) {
        r.push(year + i)
      }
      return r
    },
    updateDate () {
      this.date = JSON.parse(JSON.stringify(this.value))
      this.date[0][0] = this.$getNullsBefore(this.date[0][0] * 1)
      this.date[0][1] = this.$getNullsBefore(this.date[0][1] * 1)
      this.date[0][2] = this.$getNullsBefore(this.date[0][2] * 1)
      this.date[1][0] = this.$getNullsBefore(this.date[1][0] * 1)
      this.date[1][1] = this.$getNullsBefore(this.date[1][1] * 1)
      this.date[1][2] = this.$getNullsBefore(this.date[1][2] * 1)
    }
  },
  mounted () {
    this.updateDate()
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-form-control
    display: inline-block
    width: inherit
  .icon
    font-size: 30px
    margin-right: 7px
</style>
